import { useState, useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { CssBaseline, Toolbar, IconButton, Alert, Snackbar, Typography, Drawer, List, ListItem, ListItemText, ListItemIcon, Divider, Switch } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightIcon from "@mui/icons-material/NavigateNext";
import LanguageIcon from "@mui/icons-material/Language";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FarmSelector } from "@agricircle/shared/farms/components";
import { CropFilter, ModeOptions } from "./components/widgets";
import Product from "./components/Product";
import Home from "./components/Home";
import AssessmentModal from "./components/AssessmentModal";
import SettingsModal from "./components/SettingsModal";
import SupportModal from "./components/SupportModal";
import { useAuthContext, LoadingProvider } from "@agricircle/shared";
import { selectNextMessage, popMessage } from "@agricircle/shared/redux";
import { useAsyncApi, apiUrl, deserializeJsonapiEntity, ModalProvider, useModal } from "@agricircle/shared/hooks";
import { LanguageSelector } from "@agricircle/shared/widgets";
import { selectFarms } from "@agricircle/shared/farms/redux";
import { PollingProvider } from "./PollingContext";
import { loadFarmIQ } from "./hooks/farmiq";
import { store } from "./redux/store";
import { selectMode } from "./redux/farmiq/selectors";
import { setMode } from "./redux/farmiq";
import logo from "./assets/logo.svg";
import { version } from "./version";


const DRAWER_WIDTH = 350;


const FarmIQMenu = ({ onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openModal } = useModal();
    const mode = useSelector(selectMode);
    const farms = useSelector(selectFarms);

    function handleLogout() {
        window.location.href = `${apiUrl}/auth/logout?after_logout=https://${window.location.host}/`;
    }

    function handleToggleMode() {
        onClose();
        dispatch(setMode(mode === "iq" ? "edit" : "iq"));
    }

    function handleSettings() {
        onClose();
        openModal(t("title-settings"), <SettingsModal />);
    }

    function handleAssessment() {
        onClose();
        openModal(t("title-prepare-assessment"), <AssessmentModal />);
    }

    function handleSupport() {
        onClose();
        openModal(t("title-support"), <SupportModal />);
    }

    return (<List dense>
        <ListItem secondaryAction={<IconButton data-cy="menu-close" onClick={onClose}><CloseIcon /></IconButton>}>
            <ListItemText primary={<b>MyFarmIQ</b>} />
        </ListItem>
        <ListItem>
            <ListItemIcon><SatelliteAltIcon /></ListItemIcon>
            <ListItemText primary={t("title-edit-mode")} />
            <Switch
                edge="end"
                onChange={handleToggleMode}
                checked={mode != "iq"}
                data-cy="menu-mode"
            />
        </ListItem>
        {(mode == "iq" || null) && <ListItem
            secondaryAction={<IconButton onClick={handleAssessment}><ArrowRightIcon /></IconButton>}
        >
            <ListItemIcon><AssessmentIcon /></ListItemIcon>
            <ListItemText primary={t("title-prepare-assessment")} />
        </ListItem>}
        {/*
    <ListItem secondaryAction={<IconButton onClick={handleBilling}><ArrowRightIcon /></IconButton>}>
      <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
      <ListItemText primary={t("title-billing")} />
    </ListItem>
    */}
        <ListItem secondaryAction={<IconButton onClick={handleSettings}><ArrowRightIcon /></IconButton>}>
            <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
            <ListItemText primary={t("title-settings")} />
        </ListItem>
        <ListItem>
            <ListItemIcon><LanguageIcon /></ListItemIcon>
            <LanguageSelector />
        </ListItem>
        {(farms && farms.length > 1 || null) && <ListItem>
            <ListItemIcon><LocationOnIcon /></ListItemIcon>
            <FarmSelector onChange={onClose} canCreate={mode === "edit"} />
        </ListItem>}
        <Divider sx={{ margin: "10px 0 10px 0" }} />
        <ListItem secondaryAction={<IconButton href="/"><ArrowRightIcon /></IconButton>}>
            <ListItemIcon><OndemandVideoOutlinedIcon /></ListItemIcon>
            <ListItemText primary={t("title-guides")} />
        </ListItem>
        <ListItem secondaryAction={<IconButton onClick={handleSupport}><ArrowRightIcon /></IconButton>}>
            <ListItemIcon><SupportAgentIcon /></ListItemIcon>
            <ListItemText primary={t("title-support")} />
        </ListItem>
        <ListItem secondaryAction={<IconButton onClick={handleLogout}><ArrowRightIcon /></IconButton>}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary={t("title-logout")} />
        </ListItem>
    </List>);
};

const AppBar = styled(MuiAppBar)(() => ({
    backgroundColor: "#3F8361",
    color: "white",
}));

const FarmIQAppBar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (<div>
        <AppBar position="static">
            <Toolbar variant="dense">
                <IconButton
                    edge="start"
                    onClick={handleDrawerToggle}
                    data-cy="app-menu"
                >
                    <MenuIcon />
                </IconButton>
                <a href="/" style={{ display: "flex", alignItems: "center", textDecoration: "none", flexGrow: 1, color: "white" }}>
                    <img src={logo} style={{ width: 50 }} />
                    <Typography variant="h6" component="div" title={version}>MyFarmIQ</Typography>
                </a>
                <CropFilter />
                <ModeOptions />
            </Toolbar>
        </AppBar>
        <Drawer
            container={window.document.body}
            variant="temporary"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{ "& .MuiDrawer-paper": { boxSizing: "border-box", width: { xs: "100%", sm: DRAWER_WIDTH } } }}
        >
            <FarmIQMenu onClose={handleDrawerToggle} />
        </Drawer>
    </div>);
};

const App = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();
    const { message, isErrorMessage } = useSelector(selectNextMessage);
    const [delayed, setDelayed] = useState(true);
    const { profile, setProfile } = useAuthContext();

    function onCloseMessage() {
        dispatch(popMessage());
    }

    useEffect(() => {
        if (profile) return;
        const loadProfile = async () => {
            const result = await sendRequest("GET", "users/myself", null, null, true);
            if (result.status > 300)
                setProfile({ status: result.status });
            else
                setProfile(deserializeJsonapiEntity(result.data));
        };

        loadProfile();
        const timeout = setTimeout(() => setDelayed(false), 500);
        return () => clearTimeout(timeout);
    }, []);

    loadFarmIQ();

    // if (profile == null || !profile.status && farm == null) return delayed ? null : t("loading");
    if (profile == null) return delayed ? null : t("loading");

    return (<div>
        <ModalProvider>
            <BrowserRouter basename="/">
                <CssBaseline />
                <Snackbar open={Boolean(message)} onClose={onCloseMessage} autoHideDuration={4000}>
                    <Alert onClose={onCloseMessage} severity={isErrorMessage ? "error" : "success"} style={{ width: "100%" }}>{message}</Alert>
                </Snackbar>
                <div style={{ display: "flex" }}>
                    <Routes>
                        <Route path="/" element={<Product />} />
                        <Route path="*" element={profile.id
                            ? <div style={{ width: "100%" }}><FarmIQAppBar /><Home /></div>
                            : <Navigate to="/" replace />
                        } />
                    </Routes>
                </div>
            </BrowserRouter>
        </ModalProvider>
    </div>);
};

export default () => (<Provider store={store}>
    <LoadingProvider>
        <PollingProvider>
            <App />
        </PollingProvider>
    </LoadingProvider>
</Provider>);
