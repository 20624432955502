import TextField from "@mui/material/TextField";

export default ({ ...props }) => (<TextField
    type="text"
    margin="dense"
    variant="standard"
    {...props}
    inputProps={props.readOnly && {} || {
        onFocus: (e) => e.target.type = "date",
        onBlur: (e) => e.target.type = "text"
    }}
    InputProps={{ readOnly: Boolean(props.readOnly), disableUnderline: Boolean(props.readOnly) }}
/>);
