import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogActions, TextField, IconButton, Button, InputAdornment } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import { useAuthContext } from "@agricircle/shared";
import { displayInfoMessage } from "@agricircle/shared/redux";
import { useValidation, NOT_EMPTY_VALIDATOR, LENGTH_VALIDATOR } from "@agricircle/shared/hooks";
import { useUserApi } from "../hooks/userApi";


const ChangePasswordDialog = ({ onClose }) => {
    const { t } = useTranslation();
    const VALIDATION_OPTIONS = {
        current: { validator: NOT_EMPTY_VALIDATOR },
        password: { validator: [NOT_EMPTY_VALIDATOR, LENGTH_VALIDATOR(8, 15, t("validation-range", { min: 8, max: 15 }))] },
        confirmPassword: { validator: NOT_EMPTY_VALIDATOR }
    };
    const userApi = useUserApi();
    const validation = useValidation(VALIDATION_OPTIONS);
    const [passwords, setPasswords] = useState({ current: "", password: "", confirmPassword: "" });

    useEffect(() => validation.init(passwords), []);

    function handleValueChange(e) {
        const property = e.target.name;
        const value = validation.validate(property, e.target.value);
        if (value === undefined) return;
        const newPasswords = { ...passwords, [property]: value };
        setPasswords(newPasswords);
    };

    function handleSave() {
        userApi.changePassword(passwords.current, passwords.password, () => {
            dispatch(displayInfoMessage(t("message-password-changed")));
            onClose();
        });
    };

    const passwordAlert = validation.isValid("password")
        && validation.isValid("confirmPassword")
        && passwords.password != passwords.confirmPassword ? t("validation-password-confirmation") : null;

    return (<Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
                margin="dense"
                variant="standard"
                type="password"
                label={t("label-current-password")}
                name="current"
                value={passwords.current}
                onChange={handleValueChange}
                required
                error={!validation.isValid("current")}
                helperText={validation.error("current")}
                autoComplete="off"
                autoFocus
            />
            <TextField
                margin="dense"
                variant="standard"
                type="password"
                label={t("label-new-password")}
                name="password"
                value={passwords.password}
                onChange={handleValueChange}
                required
                error={!validation.isValid("password")}
                helperText={validation.error("password")}
                autoComplete="off"
            />
            <TextField
                margin="dense"
                variant="standard"
                type="password"
                label={t("label-verify-password")}
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={handleValueChange}
                required
                error={!validation.isValid("confirmPassword") || Boolean(passwordAlert)}
                helperText={validation.error("confirmPassword") || passwordAlert}
                autoComplete="off"
            />
        </DialogContent>
        <DialogActions>
            <Button
                size="small"
                variant="text"
                data-cy="button-prompt-cancel"
                onClick={onClose}
                sx={{ minWidth: "15ch" }}
            >
                {t("btn-cancel")}
            </Button>
            <Button
                size="small"
                variant="text"
                data-cy="button-prompt-ok"
                onClick={handleSave}
                disabled={!validation.isValid() || Boolean(passwordAlert)}
                sx={{ minWidth: "15ch" }}
            >
                {t("btn-save")}
            </Button>
        </DialogActions>
    </Dialog>);
};


export default () => {
    const { profile } = useAuthContext();
    const [editPassword, setEditPassword] = useState(false);

    function handleTogglePassword() {
        setEditPassword(!editPassword);
    };

    if (!profile) return null;

    return (<DialogContent sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
        <TextField
            defaultValue={profile.name}
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                startAdornment: <InputAdornment position="start">
                    <AccountCircle />
                </InputAdornment>,
                endAdornment: <InputAdornment position="end">
                    <IconButton onClick={handleTogglePassword} edge="end">
                        <KeyIcon />
                    </IconButton>
                </InputAdornment>
            }}
            variant="standard"
            fullWidth
        />
        {(editPassword || null) && <ChangePasswordDialog onClose={() => setEditPassword(false)} />}
    </DialogContent>);
};
