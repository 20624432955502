import { useState, useMemo, useRef } from "react";
import { Box, TextField, Popover, Stack, IconButton, InputAdornment } from "@mui/material";
import { RichTreeView } from "@mui/x-tree-view";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useCropUtils } from "../../hooks/cropUtils";

const buildCropTree = (crops, favorites, showFavorites) => {
    const nodeIndex = {};
    const cropTree = [];

    if (showFavorites && favorites && Object.keys(favorites).length > 0)
        cropTree.push({
            id: "favorites",
            label: "Favorites",
            children: Object.keys(favorites).map(k => ({ id: `f_${k}`, label: favorites[k] }))
        });
    crops.forEach(c => {
        const cropNode = {
            id: c.id,
            label: c.name
        };
        //if (showFavorites && favorites && c.id in favorites)
        //  cropNode.disabled = true;
        if (c.id.indexOf(".") < 0)  // top level
            cropTree.push(cropNode);
        else {
            let parentId = c.id;
            let parentNode = null;
            do {
                parentId = parentId.substring(0, parentId.lastIndexOf("."));
                parentNode = nodeIndex[parentId];
            } while (parentId && !parentNode);
            if (parentNode.children)
                parentNode.children.push(cropNode);
            else
                parentNode.children = [cropNode];
        }
        nodeIndex[cropNode.id] = cropNode;
    });
    return { cropTree, nodeIndex };
};

export default ({ value, showFavorites, onChange, ...rest }) => {
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const wrapperRef = useRef();
    const cropUtils = useCropUtils();
    const readOnly = !onChange;

    const { cropTree, nodeIndex } = useMemo(() => {
        if (!cropUtils.crops || readOnly) return {};
        return buildCropTree(cropUtils.crops, cropUtils.favoriteCrops, showFavorites);
    }, [cropUtils.crops != null]);

    function handleShowTree() {
        if (readOnly) return;
        setAnchorEl(wrapperRef.current);
        if (value && showFavorites && cropUtils.favoriteCrops && (value in cropUtils.favoriteCrops)) {
            setSelected(`f_${value}`);
            setExpanded(["favorites"]);
            return;
        }
        setSelected(value || "");
        const expanded = [];
        if (value) {
            let nodeId = value.substring(0, value.lastIndexOf("."));
            while (nodeId) {
                if (nodeId in nodeIndex)
                    expanded.push(nodeId);
                nodeId = nodeId.substring(0, nodeId.lastIndexOf("."));
            }
        }
        setExpanded(expanded);
    }

    function handleHideTree() {
        setAnchorEl(null);
    }

    function handleToggle(e, cIds) {
        if (e.isDefaultPrevented()) return;
        setExpanded(cIds);
    }

    function handleSelect(e, cId) {
        if (e.isDefaultPrevented() || cId == "favorites") return;
        const isLabelClick = e.target.className == "MuiTreeItem-label";
        if (isLabelClick)
            e.preventDefault();
        const isFavorite = cId.startsWith("f_");
        const realId = isFavorite ? cId.substring(2) : cId;
        if (!isLabelClick && !isFavorite && nodeIndex[realId].children) return;
        if (realId != value)
            onChange(realId);
        handleHideTree();
    }

    return (<Box ref={wrapperRef}>
        {(!anchorEl || !cropTree || null) && <TextField
            value={cropUtils.cropName(value) || "-"}
            placeholder="select crop"
            InputProps={{
                readOnly: true,
                disableUnderline: readOnly,
                endAdornment: <InputAdornment position="end"><ArrowDropDownIcon /></InputAdornment>
            }}
            onClick={readOnly ? null : handleShowTree}
            margin="dense"
            variant="standard"
            {...rest}
        />}

        {anchorEl && (<Popover
            open={true}
            anchorEl={anchorEl}
            onClose={handleHideTree}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ margin: 1 }}>
                <span>Select crop:</span>
                <IconButton
                    data-cy="cancel-crop-selection"
                    className="fa fa-xmark"
                    onClick={handleHideTree}
                    size="small" />
            </Stack>
            <RichTreeView
                items={cropTree}
                expansionTrigger="iconContainer"
                selected={selected}
                onSelectedItemsChange={handleSelect}
                expandedItems={expanded}
                onExpandedItemsChange={handleToggle}
            />
        </Popover>)}
    </Box>);
};
