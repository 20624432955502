import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { displayErrorMessage, displayInfoMessage } from "@agricircle/shared/redux";
import { useAsyncApi } from "@agricircle/shared/hooks";

export const useUserApi = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();

    ////////////////// Change password
    const changePassword = async (currentPassword, newPassword, callback) => {
        const payload = { old_password: currentPassword, new_password: newPassword };
        const result = await sendRequest("POST", "change_password", payload);
        if (result.status < 300) {
            dispatch(displayInfoMessage(t("message-password-changed")));
            if (callback) callback();
        } else {
            dispatch(displayErrorMessage(t("error-change-password"), result.data));
        }
        return result;
    };

    return { changePassword };
};
