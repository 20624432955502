
import { LatLng } from "leaflet";
import { createSelector } from "reselect";

const selectState = (state) => state.farms;

export const selectFarms = createSelector(selectState, (state) => state.farms);

export const selectFarm = createSelector(selectState, (state) => state.selectedFarm);

export const selectFields = createSelector(selectState, (state) => (state.selectedFarm?.fields || null));

export const selectEditFields = createSelector(selectState, (state) => {
    if (!state.selectedFarm?.fields) return null;
    if (!state.farmImport?.fields?.length || state.farmImport?.id !== state.selectedFarm.id) return state.selectedFarm.fields;
    const sortedImports = state.farmImport.fields.toSorted((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
    return [...state.selectedFarm.fields, ...sortedImports];
});

export const selectImportStatus = createSelector(selectState, (state) => {
    if (!state.selectedFarm?.id) return null;
    if (!state.farmImport) return "none";
    if (state.selectedFarm.id !== state.farmImport.farm_id) return "other";
    if (!state.farmImport.error && !state.farmImport.errors && !state.farmImport.fields) return "in_progress";
    if (!state.farmImport.fields) return "error";
    return "review";
});

export const selectImportFarmId = createSelector(selectState, (state) => {
    return state.farmImport?.farm_id || null;
});

export const selectMapPosition = createSelector(selectState, (state) => {
    if (!state.selectMapPosition) return null;
    return {
        zoom: state.selectMapPosition.zoom,
        center: LatLng(state.selectMapPosition.center.lat, state.selectMapPosition.center.lng)
    };
});
export const selectMapLayer = createSelector(selectState, (state) => state.mapLayer);
