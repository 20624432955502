import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { usePolling } from "../../PollingContext";
import { selectMode } from "../../redux/farmiq/selectors";

const AssessModeOptions = () => {
    const { t } = useTranslation();
    const { isProcessing } = usePolling();

    return (<SatelliteAltIcon
        title={t("title-prepare-assessment")}
        disabled={isProcessing}
        sx={!isProcessing ? {} : {
            color: "white",
            animation: "spin 5s linear infinite",
            "@keyframes spin": {
                "0%": {
                    transform: "rotate(360deg)",
                },
                "100%": {
                    transform: "rotate(0deg)",
                },
            },
        }}
    />);
};

export default () => {
    const mode = useSelector(selectMode);

    return (<Box display="flex" justifyContent="center" alignItems="center" sx={{ color: "#404040" }}>
        {mode == "iq" ? <AssessModeOptions /> : <ModeEditIcon />}
    </Box>);
};
