import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAsyncApi, deserializeJsonapiEntity, deserializeJsonapiEntities, serializeToJsonapiEntity } from "../../hooks/asyncApi";
import { displayInfoMessage, displayErrorMessage } from "../../redux/messages";
import { setFarms, setSelectedFarm, setFarmImport } from "../redux/reducer";
import { selectFarms, selectFarm } from "../redux/selectors";


export const useFarmApi = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();

    ////////////////// List fields

    const listFields = async (farmId, options, callback) => {
        const attributes = options?.extraAttributes ? `,${options.extraAttributes.join(",")}` : "";
        const result = await sendRequest("GET", `fields?filter[farm]=${farmId}&fields[fields]=id,name,area,center_point,boundaries${attributes},farm&sort=name`);
        if (result.isError)
            dispatch(displayErrorMessage(t("farms.errors.list-fields"), result.data));
        else
            callback && callback(deserializeJsonapiEntities(result.data));
        return result;
    };

    ////////////////// Create farm
    const createFarm = async (attributes, callback) => {
        const payload = serializeToJsonapiEntity(null, "farms", attributes);
        const result = await sendRequest("POST", "farms", payload);
        if (result.status < 300) {
            callback && callback(deserializeJsonapiEntity(result.data));
        } else {
            dispatch(displayErrorMessage(t("farms.errors.create-farm"), result.data));
        }
        return result;
    };

    ////////////////// Delete farm
    const deleteFarm = async (farmId, callback) => {
        const result = await sendRequest("DELETE", `farms/${farmId}`);
        if (result.status < 300) {
            callback && callback();
        } else {
            dispatch(displayErrorMessage(t("farms.errors.delete-farm"), result.data));
        }
        return result;
    };

    ////////////////// Create field
    const createField = async (attributes, farmId, callback) => {
        const payload = serializeToJsonapiEntity(null, "fields", attributes);
        payload.data.relationships = { farm: { data: { type: "farms", id: farmId } } };
        const result = await sendRequest("POST", "fields", payload);
        if (result.status < 300) {
            dispatch(displayInfoMessage(t("farms.message-field-created")));
            callback && callback(deserializeJsonapiEntity(result.data));
        } else {
            dispatch(displayErrorMessage(t("farms.errors.create-field"), result.data));
        }
        return result;
    };

    ////////////////// Update field
    const patchField = async (fieldId, attributes, callback) => {
        const payload = serializeToJsonapiEntity(fieldId, "fields", attributes);
        const result = await sendRequest("PATCH", `fields/${fieldId}`, payload);
        if (result.status < 300) {
            dispatch(displayInfoMessage(t("farms.message-field-updated")));
            callback && callback(deserializeJsonapiEntity(result.data));
        } else {
            dispatch(displayErrorMessage(t("farms.errors.update-field"), result.data));
        }
        return result;
    };

    ////////////////// Delete field
    const deleteField = async (fieldId, callback) => {
        const result = await sendRequest("DELETE", `fields/${fieldId}`);
        if (result.status < 300) {
            dispatch(displayInfoMessage(t("farms.message-field-deleted")));
            callback && callback();
        } else {
            dispatch(displayErrorMessage(t("farms.errors.delete-field"), result.data));
        }
        return result;
    };

    ////////////////// Get field
    const getField = async (fieldId, callback) => {
        const result = await sendRequest("GET", `fields/${fieldId}`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to get field", result.data));
        else
            callback && callback(deserializeJsonapiEntity(result.data));
        return result;
    };


    ////////////////// import status
    const importStatus = async (callback) => {
        const result = await sendRequest("GET", "fields/import/status");
        if (result.isError && result.status != 418)
            dispatch(displayErrorMessage("Failed to check if import is ongoing", result.data));
        else
            callback && callback((result.status != 204 || null) && result.data);
        return result;
    };

    ////////////////// List imported fields
    const listImportFields = async (callback) => {
        const result = await sendRequest("GET", "fields/import");
        if (result.isError && result.status != 418)
            dispatch(displayErrorMessage("Failed to list imported fields", result.data));
        else
            callback && callback(result.data || result.error || []);
        return result;
    };

    ////////////////// Start import fields
    const startImport = async (farmId, formData, callback) => {
        const result = await sendRequest("POST", `fields/import?farm_id=${farmId}`, formData);
        if (result.isError) {
            if (result.status == 422 && result.data) {
                if (callback)
                    callback(result.data);
            } else if (result.status != 422)
                dispatch(displayErrorMessage("Failed to start import", result.data));
        } else
            callback && callback(result.data);
        return result;
    };

    ////////////////// Patch field
    const patchImportField = async (fieldId, attributes, callback) => {
        const result = await sendRequest("PATCH", `fields/import/${fieldId}`, attributes);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to update import field", result.data));
        else {
            dispatch(displayInfoMessage("Import field updated"));
            callback && callback(result.data);
        }
        return result;
    };

    ////////////////// Delete import field
    const deleteImportField = async (fieldId, callback) => {
        const result = await sendRequest("DELETE", `fields/import/${fieldId}`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to remove import field", result.data));
        else {
            dispatch(displayInfoMessage("Import field removed"));
            callback && callback();
        }
        return result;
    };

    ////////////////// Reset import
    const resetImport = async (callback) => {
        const result = await sendRequest("DELETE", "fields/import");
        if (result.isError)
            dispatch(displayErrorMessage("Failed to clear import", result.data));
        else {
            dispatch(displayInfoMessage("Import cleared"));
            callback && callback();
        }
        return result;
    };

    ////////////////// Activate fields
    const activateImportFields = async (fieldIds, callback) => {
        const result = await sendRequest("PUT", "fields/import", fieldIds);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to activate field", result.data));
        else
            callback && callback();
        return result;
    };

    ////////////////// Rename to property
    const renameImportFields = async (propertyName, callback) => {
        const result = await sendRequest("POST", `fields/import/rename_by_property?property_name=${propertyName}`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to rename property", result.data));
        else
            callback && callback(result.data);
        return result;
    };

    return { listFields, createFarm, deleteFarm, createField, patchField, deleteField, getField, importStatus, listImportFields, startImport, patchImportField, deleteImportField, resetImport, activateImportFields, renameImportFields };
};


export const useLoadFarms = (profileId, editMode, options) => {
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();
    const farmApi = useFarmApi();
    const farms = useSelector(selectFarms);
    const farm = useSelector(selectFarm);

    useEffect(() => {
        if (!profileId || farms != null) return;

        async function asyncLoadFarms() {
            let result = await sendRequest("GET", "farms?fields[farms]=name,location,fields&sort=name");
            if (result.isError) {
                dispatch(displayErrorMessage("Failed to list farms", result.data));
                return false;
            }
            const loadedFarms = deserializeJsonapiEntities(result.data);
            dispatch(setFarms(loadedFarms));
        };

        asyncLoadFarms();
    }, [profileId, Boolean(farms)]);

    useEffect(() => {
        if (!farms) return;

        async function asyncLoadFields() {
            let selectedFarmId = farm?.id;
            let fields;
            if (farms.length) {
                selectedFarmId = farms.find(f => f.id === selectedFarmId)?.id || farms[0].id;
                const result = await farmApi.listFields(selectedFarmId);
                if (result.isError) {
                    dispatch(displayErrorMessage("Failed to list fields", result.data));
                    return;
                }
                fields = deserializeJsonapiEntities(result.data);
                if (editMode) {
                    const result = await farmApi.listImportFields();
                    dispatch(setFarmImport(result.isError ? null : result.data));
                }
                dispatch(setSelectedFarm(selectedFarmId, fields));
            } else {
                dispatch(setSelectedFarm(null, null));
            }
        };

        asyncLoadFields();
    }, [farm?.id, Boolean(farm?.fields), Boolean(farms)]);
};
